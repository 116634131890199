import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INotification } from 'types/interfaces/Notifications';

interface NotificationState {
  notifications: INotification[];
  loading: boolean;
  isFirstLoaded: boolean;
}

const initialState: NotificationState = {
  notifications: [],
  loading: true,
  isFirstLoaded: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setIsNotificationsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsNotificationsFirstLoaded: (state) => {
      state.isFirstLoaded = true;
    },
    setNotifications(state, action: PayloadAction<INotification[]>) {
      state.notifications = action.payload;
    },
    setNotificationStatusRead(
      state,
      action: PayloadAction<{ notificationId: number }>
    ) {
      state.notifications = state.notifications.map((notificationItem) => {
        if (notificationItem.id === action.payload.notificationId)
          return { ...notificationItem, is_new: false };

        return notificationItem;
      });
    },
    addNewNotification(state, action: PayloadAction<INotification>) {
      let alreadyExist = false;
      const newNotification = action.payload;

      const updatedNotifications = state.notifications.map(
        (notificationItem) => {
          if (
            notificationItem.id === newNotification.id ||
            (notificationItem.sender?.ulid_id ===
              newNotification.sender?.ulid_id &&
              notificationItem.type === newNotification.type)
          ) {
            alreadyExist = true;

            return { ...notificationItem, ...newNotification };
          }
          return notificationItem;
        }
      );

      state.notifications = alreadyExist
        ? updatedNotifications
        : [newNotification, ...updatedNotifications];
    },

    resetState() {
      return initialState;
    },
  },
});

export const {
  setIsNotificationsLoading,
  setIsNotificationsFirstLoaded,
  setNotifications,
  setNotificationStatusRead,
  addNewNotification,

  resetState,
} = notificationsSlice.actions;

export const notifications = notificationsSlice.reducer;
