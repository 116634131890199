import axios from 'axios';

import { MirrorService } from 'services/MirrorService';

export const httpClient = axios.create({
  baseURL: MirrorService.apiUrl,
  timeout: 60000,
  withCredentials: true,
});

export const setXPrevUrl = (href: string) => {
  httpClient.defaults.headers.common['X-Prev-Url'] = href;
};

export const setXRefererUrl = (href: string) => {
  httpClient.defaults.headers.common['X-Referer'] = href;
};
