import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { TrackingApi } from 'api/TrackingApi';

export const useProfileActionsTracking = () => {
  const { pathname } = useLocation();

  const trackProfileActionClick = useCallback(
    ({
      userId,
      actionName,
    }: {
      userId: string;
      actionName:
        | 'profile_menu'
        | 'block_profile'
        | 'hide_dialog'
        | 'unhide_dialog'
        | 'report_profile'
        | 'report_profile_full';
    }) => {
      TrackingApi.trackClick({
        name: actionName,
        interaction_with_user_ulid: userId || '',
        url: `${window.location.origin}${pathname}`,
        origin: `${window.location.origin}${pathname}`,
      }).catch((err) => err);
    },
    [pathname]
  );

  return {
    trackProfileActionClick,
  };
};
