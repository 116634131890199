import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  IS_PWA_ON_MAIL_CONFIRM_SHOWN,
  PWA_POPUPS,
} from 'helpers/pwa/constants';
import { getSessionStorageItem } from 'helpers/sessionStorage';
import { getIsAuthenticated, getUser } from 'store/auth/selectors';
import {
  setActiveSystemPopup,
  SystemPopupTypes,
} from 'store/systemPopup/systemPopupSlice';

import { useSystemSearchQueries } from './useSystemSearchQueries';

export const useStarterPopup = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const currentUser = useSelector(getUser);

  const { starterPopupQuery } = useSystemSearchQueries();

  const isPWAOnMailConfirmShown = useMemo(
    () => getSessionStorageItem(IS_PWA_ON_MAIL_CONFIRM_SHOWN),
    []
  );

  useEffect(() => {
    if (
      isAuthenticated &&
      starterPopupQuery.value ===
        String(SystemPopupTypes.MailConfirmAfterReg) &&
      currentUser
    ) {
      dispatch(
        setActiveSystemPopup({
          type: SystemPopupTypes.MailConfirmAfterReg,
        })
      );

      return;
    }

    if (
      isAuthenticated &&
      !isPWAOnMailConfirmShown &&
      starterPopupQuery.value === String(SystemPopupTypes.PWAOnMailConfirm) &&
      currentUser
    ) {
      dispatch(
        setActiveSystemPopup({
          type: SystemPopupTypes.PWAOnMailConfirm,
          params: { name: PWA_POPUPS.AfterWizard, isWizard: true },
        })
      );

      return;
    }

    if (
      isAuthenticated &&
      starterPopupQuery.value === String(SystemPopupTypes.InitCredits) &&
      currentUser
    ) {
      dispatch(
        setActiveSystemPopup({
          type: SystemPopupTypes.InitCredits,
          params: {
            username: currentUser.name,
          },
        })
      );
    }
  }, [
    currentUser,
    dispatch,
    isAuthenticated,
    isPWAOnMailConfirmShown,
    starterPopupQuery.value,
  ]);
};
