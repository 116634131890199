import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MirrorService } from 'services/MirrorService';
import { ConversionEventType } from 'types/enums/ConversionEvent';

import { ConversionsApi } from 'api/ConversionsApi';
import { FacebookPixel } from 'helpers/analytics/fbPixel';
import { Gtm } from 'helpers/analytics/gtm';
import { updateInitializedPixelsIds } from 'store/common/commonSlice';
import { getInitializedPixelsIds } from 'store/common/selectors';

//  ! do not use useEffect here
export const useConversionTracking = () => {
  const dispatch = useDispatch();

  const initializedPixelsIds = useSelector(getInitializedPixelsIds);

  const conversionEventHandler = useCallback(
    (event: any) => {
      const conversionPixelId = event?.data?.pixel_id;
      const eventID = event?.data?.event_id;

      if (window.location !== window.parent?.location) return;
      // ? disable tracking for iframe)

      Gtm.trackConversionEventByName(event.data);

      if (!conversionPixelId || !eventID) {
        return;
      }

      const isPixelNotInitialized =
        !initializedPixelsIds.includes(conversionPixelId);

      if (isPixelNotInitialized)
        FacebookPixel.init({
          newPixelId: conversionPixelId,
        });

      FacebookPixel.trackEvent(event.data);

      if (isPixelNotInitialized)
        dispatch(updateInitializedPixelsIds(conversionPixelId));
    },
    [dispatch, initializedPixelsIds]
  );

  const sendSingleFacebookConversionHandler = useCallback(
    async ({ eventType }: { eventType: ConversionEventType }) => {
      try {
        const eventData = await ConversionsApi.createConversionData(eventType);

        conversionEventHandler({ data: eventData });
      } catch (error) {
        console.error('Wrong pixel setup');
      }
    },
    [conversionEventHandler]
  );

  const sendSingleGtmConversionHandler = useCallback(
    ({ eventType }: { eventType: ConversionEventType }) => {
      if (MirrorService.gtmId) Gtm.trackConversionEventByType(eventType);
    },
    []
  );

  return {
    conversionEventHandler,
    sendSingleFacebookConversionHandler,
    sendSingleGtmConversionHandler,
  };
};
