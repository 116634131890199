import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from './localStorage';

export const AUTH_TOKEN_KEY = 'auth_token';
export const AUTH_EXPIRE_KEY = 'auth_token_expire';

interface AccessTokenData {
  token: string | null;
  expire: number | null;
}

const PRE_REFRESH_KEF = 0.7; // ? do refresh when token expired on 70%
const MILLISECONDS = 1000;

export const getAccessTokenDataFromStorage = (): AccessTokenData => {
  const expire = getLocalStorageItem(AUTH_EXPIRE_KEY);

  return {
    token: getLocalStorageItem(AUTH_TOKEN_KEY),
    expire: expire ? +expire : 0,
  };
};

export const setAccessTokenDataToStorage = ({
  token,
  expire,
}: AccessTokenData) => {
  setLocalStorageItem(AUTH_TOKEN_KEY, token);
  setLocalStorageItem(
    AUTH_EXPIRE_KEY,
    Date.now() + (expire || 0) * PRE_REFRESH_KEF * MILLISECONDS
  );
};

export const removeAccessTokenFromStorage = () => {
  removeLocalStorageItem(AUTH_TOKEN_KEY);
  removeLocalStorageItem(AUTH_EXPIRE_KEY);
};
