import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useProfileActionsTracking } from 'hooks/analytics/useProfileActionsTracking';
import { useToast } from 'hooks/useToast';
import { reportUserProfileThunk } from 'store/profile/thunks';
import { ReportUserPopupParams } from 'store/systemPopup/systemPopupSlice';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { BaseTextarea } from 'components/base/BaseTextarea';
import { Popup } from 'components/shared/Popup';

import css from './reportUserPopup.module.sass';

interface Props {
  params: ReportUserPopupParams;
  onClose: () => void;
}

interface FormValues {
  message: string;
}

export const ReportUserPopup: React.FC<Props> = ({ params, onClose }) => {
  const dispatch = useDispatch();
  const { showSuccessToast } = useToast();

  const { trackProfileActionClick } = useProfileActionsTracking();

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { message: '' },
  });

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleFormSubmit = useCallback(
    (formValues: FormValues) => {
      dispatch(
        reportUserProfileThunk({
          userId: params.userId,
          message: formValues.message,
        })
      );

      trackProfileActionClick({
        userId: params.userId,
        actionName: 'report_profile_full',
      });

      showSuccessToast({ title: 'User was reported' });

      onClose();
    },
    [
      dispatch,
      onClose,
      params.userId,
      showSuccessToast,
      trackProfileActionClick,
    ]
  );

  return (
    <Popup onClose={handleClose}>
      <form className={css.root} onSubmit={handleSubmit(handleFormSubmit)}>
        <h2 className={css.title}>
          We’re sorry about your experience! <br />
          Tell us what happened
        </h2>

        <Controller
          as={BaseTextarea}
          control={control}
          name="message"
          placeholder="Your feedback"
          maxRows={7}
          maxSymbols={1000}
          className={css.formTextarea}
        />

        <BaseButton
          className={css.btn}
          type={ButtonTypes.Accent}
          nativeType="submit"
        >
          Report
        </BaseButton>
      </form>
    </Popup>
  );
};
