import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { MirrorService } from 'services/MirrorService';

type PropTypes = {
  dark?: boolean;
  withoutLink?: boolean;
  className?: string;
};

export const Logo: React.FC<PropTypes> = memo(
  ({ dark = false, withoutLink = false, className }) => {
    const height = '26px';

    if (withoutLink) {
      return (
        <img
          height={height}
          className={className}
          src={`/logos/${MirrorService.siteName}-${
            dark ? 'dark' : 'light'
          }.svg`}
        />
      );
    }

    return (
      <Link to="/">
        <img
          height={height}
          className={className}
          src={`/logos/${MirrorService.siteName}-${
            dark ? 'dark' : 'light'
          }.svg`}
        />
      </Link>
    );
  }
);
