import { RootState } from 'store/rootReducer';

export const getProfile = (state: RootState) => state.profile.profile;

export const getLastActiveUserProfileInfo = (state: RootState) =>
  state.profile.lastActiveUserProfileInfo;

export const getIsMediaAccessesLoading = (state: RootState) =>
  state.profile.isMediaAccessesLoading;

export const getMediaAccesses = (contactId?: string) => (state: RootState) => {
  return contactId ? state.profile.mediaAccesses[contactId]?.accesses : [];
};

export const getMediaAccessesByMediaType =
  (contactId?: string) => (state: RootState) => {
    return contactId
      ? state.profile.mediaAccesses[contactId]?.accessesByMediaType
      : [];
  };
