import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { MirrorService } from 'services/MirrorService';

import { useLowBalanceAnalytics } from 'hooks/analytics/useLowBalancePopupAnalytics';
import { QueryKeys } from 'hooks/useSystemSearchQueries';
import { getUser } from 'store/auth/selectors';
import { getCreditsCurrencySelector } from 'store/payment/selectors';
import { LowBalancePopupParams } from 'store/systemPopup/systemPopupSlice';

import { ReactComponent as CrossIcon } from 'assets/icons/popups/balance-low-cross.svg';
import { ReactComponent as LeftIcon } from 'assets/icons/popups/lines-left.svg';
import { ReactComponent as RightIcon } from 'assets/icons/popups/lines-right.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { Popup } from 'components/shared/Popup';
import { UserAvatar } from 'components/shared/UserAvatar';

import css from './lowBalancePopup.module.sass';

interface Props {
  params: LowBalancePopupParams;
  onClose: () => void;
}

export const LowBalancePopup: React.FC<Props> = ({ params, onClose }) => {
  const history = useHistory();

  const { pathname } = useLocation();

  const currentUser = useSelector(getUser);

  const creditsCurrency = useSelector(getCreditsCurrencySelector);

  const { trackLowBalanceShow, trackLowBalanceClose, trackLowBalanceClick } =
    useLowBalanceAnalytics();

  const handleBtnClick = useCallback(() => {
    trackLowBalanceClick();

    onClose();

    history.push({
      pathname: `/upgrade/balance`,
      search: queryString.stringify(
        {
          [QueryKeys.Funnel]: params.funnel,
          [QueryKeys.SuccessUrlPathname]: params?.url || pathname,
          min_income: params?.min_income,
          a_gift_id: params?.a_gift_id,
        },
        { skipNull: true, skipEmptyString: true }
      ),
    });
  }, [
    trackLowBalanceClick,
    onClose,
    history,
    params.funnel,
    params?.url,
    params?.min_income,
    params?.a_gift_id,
    pathname,
  ]);

  useEffect(() => {
    trackLowBalanceShow();

    return () => trackLowBalanceClose();
  }, [trackLowBalanceClose, trackLowBalanceShow]);

  return (
    <Popup onClose={onClose}>
      <div className={css.root}>
        <div className={css.iconBox}>
          <LeftIcon className={css.lines} />
          <UserAvatar
            size="70px"
            url={MirrorService.resolveImagePath(params?.photoUrl)}
            className={css.icon}
          />
          <CrossIcon className={css.linesAccent} />
          <UserAvatar
            size="70px"
            url={MirrorService.resolveImagePath(currentUser?.photo_url)}
            className={css.icon}
          />
          <RightIcon className={css.lines} />
        </div>
        <h2 className={css.title}>Credit balance is low...</h2>
        {!params.type && (
          <p className={css.text}>
            Keep the conversation with {params.name}. <br />
            Refill your {creditsCurrency} now to chat.
          </p>
        )}

        {params.type === 'media' && (
          <p className={css.text}>
            Refill your {creditsCurrency} now to open this media.
          </p>
        )}

        {params.type === 'mailRead' && (
          <p className={css.text}>
            Refill your {creditsCurrency} now to open this mail.
          </p>
        )}

        <BaseButton
          className={css.btn}
          type={ButtonTypes.Accent}
          onClick={handleBtnClick}
        >
          GET CREDITS
        </BaseButton>
      </div>
    </Popup>
  );
};
