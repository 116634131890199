import {
  ConversionEventType,
  FacebookConversionEventName,
  GtmConversionEventName,
} from 'types/enums/ConversionEvent';

import { httpClient } from './httpClient';

type FacebookEventsIdsResponse = Record<FacebookConversionEventName, string>;

export interface ConversionQueue {
  partner_type: 'GTM' | 'FACEBOOK';
  event_type: ConversionEventType;
  execute_at: string;
}

export const ConversionsApi = {
  async getFacebookEventsIds() {
    const { data } = await httpClient.get<FacebookEventsIdsResponse>(
      '/conversion/get-ids'
    );

    return data;
  },
  async createConversionData(eventType: ConversionEventType) {
    const { data } = await httpClient.post('conversion/create-data', {
      event_type: eventType,
    });

    return data;
  },
  async forceFacebookServerConversion(eventType: ConversionEventType) {
    const { data } = await httpClient.post('conversion/send', {
      event_type: eventType,
    });

    return data;
  },

  forceGtmConversion(payload: { eventName: GtmConversionEventName }) {
    return httpClient.post('/conversion/track', {
      event_name: payload.eventName,
      status: true,
    });
  },

  async getConversionsQueue() {
    const { data } = await httpClient.get<ConversionQueue[]>(
      'conversion/get-queue'
    );

    return data;
  },
};
