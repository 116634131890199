import { getLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';

import { PWA_SW_PATH, PWA_TRACKING_COOKIES_STORAGE_KEY } from './constants';

export const isPWAMode = () => {
  return window.matchMedia('(display-mode: standalone)').matches;
};

export const getPWATrackingCookies = () => {
  return getLocalStorageItem(PWA_TRACKING_COOKIES_STORAGE_KEY);
};

export const setPWATrackingCookies = (cookies: string) => {
  setLocalStorageItem(PWA_TRACKING_COOKIES_STORAGE_KEY, cookies);
};

export const registerPWAServiceWorker = async () => {
  if (!('serviceWorker' in navigator)) return;

  try {
    await navigator.serviceWorker.register(PWA_SW_PATH);
  } catch (err) {
    console.error('registerPwaServiceWorker: ', err);
  }
};
