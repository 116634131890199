import { useCallback, useEffect } from 'react';

const VIEVPORT_RESIZE_TO_PX = 0.01;

// Hack to correctly understand vh units in a mobile browser
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

export const useUpdateBodyHeightOnResize = () => {
  const changeBodyHeight = useCallback(() => {
    const bodyVh = window.innerHeight * VIEVPORT_RESIZE_TO_PX;
    document.documentElement.style.setProperty('--vh', `${bodyVh}px`);
  }, []);

  useEffect(() => {
    changeBodyHeight();

    window.addEventListener('resize', changeBodyHeight);

    return () => {
      window.removeEventListener('resize', changeBodyHeight);
      document.documentElement.style.removeProperty('--vh');
    };
  }, [changeBodyHeight]);
};
