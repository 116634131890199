import React, { memo } from 'react';

import { MirrorService } from 'services/MirrorService';

interface Props {
  className?: string;
}

export const Favicon: React.FC<Props> = memo(({ className }) => {
  return (
    <img
      className={className}
      alt="favicon"
      src={`/favicons/${MirrorService.siteName}-192x192.png`}
    />
  );
});
