import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';

export const getIsAuthorizationLoading = (state: RootState) =>
  state.auth.loading;
export const getIsAuthenticated = (state: RootState) =>
  !!state.auth.isAuthenticated;
export const getUser = (state: RootState) => state.auth.user;

export const getUserEmail = createSelector(getUser, (user) => user?.email);
export const getUserEmailSubscriptionStatus = createSelector(
  getUser,
  (user) => user?.subscription_email_status
);
