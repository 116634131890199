import { useCallback } from 'react';

import { TrackingApi } from 'api/TrackingApi';

export enum ToastActionName {
  Show = 'show',
  Close = 'close',
  Click = 'click',
}

export const useToastAnalytics = () => {
  const trackToast = useCallback(
    ({
      action,
      senderId,
      notificationId,
      notificationTitle,
      notificationDescription,
    }: {
      action: ToastActionName;
      senderId: string;
      notificationId: number;
      notificationTitle: string;
      notificationDescription: string;
    }) => {
      TrackingApi.trackToast({
        action,
        sender_ulid: senderId,
        notification_id: notificationId,
        notification_title: notificationTitle,
        notification_description: notificationDescription,
        url: window.location.href,
      }).catch();
    },
    []
  );

  return { trackToast };
};
