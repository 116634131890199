import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MediaType } from 'types/enums/MediaType';
import { UserGender } from 'types/enums/UserGender';
import { UserProfile } from 'types/interfaces/user/UserProfile';

interface BaseUserProfileInfo {
  ulid_id: string;
  name?: string;
  photoUrl?: string | null;
  gender: UserGender;
}

interface ProfileState {
  profile: UserProfile;
  mediaAccesses: Record<
    string,
    {
      accesses: Record<MediaType, string[]>;
      accessesByMediaType: MediaType[];
    }
  >;

  lastActiveUserProfileInfo: BaseUserProfileInfo | null;

  isMediaAccessesLoading: boolean;
}

const initialState: ProfileState = {
  profile: {
    ulid_id: '',
    name: '',
    lastname: '',
    date_of_birth: '',
    age: 25,
    gender: UserGender.Male,
    city: null,
    country: null,
    private_photos: [],
    public_photos: [],
    liked: false,
    is_additional_info_hidden: false,
    is_popular: false,
    is_online: false,
    can_view: false,
    details: null,
  },

  lastActiveUserProfileInfo: null,

  mediaAccesses: {},
  isMediaAccessesLoading: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<UserProfile>) {
      state.profile = action.payload;
    },
    updateProfile(state, action: PayloadAction<Partial<UserProfile>>) {
      state.profile = { ...state.profile, ...action.payload };
    },

    setLastActiveUserProfileInfo(
      state,
      action: PayloadAction<BaseUserProfileInfo>
    ) {
      state.lastActiveUserProfileInfo = action.payload;
    },

    setContactMediaAccesses(
      state,
      action: PayloadAction<{
        contactId: string;
        accesses: Record<MediaType, string[]>;
        accessesByMediaType: MediaType[];
      }>
    ) {
      const { contactId, accesses, accessesByMediaType } = action.payload;

      state.mediaAccesses[contactId] = {
        accesses,
        accessesByMediaType,
      };
    },

    updateMediaAccess(
      state,
      action: PayloadAction<{
        contactId: string;
        newMediaId: number;
        newMediaType: MediaType;
      }>
    ) {
      const { contactId, newMediaId, newMediaType } = action.payload;

      state.mediaAccesses[contactId] = {
        ...state.mediaAccesses[contactId],
        accesses: {
          ...state.mediaAccesses[contactId].accesses,
          [newMediaType]: [
            ...(state.mediaAccesses?.[contactId]?.accesses?.[newMediaType] ||
              []),
            String(newMediaId),
          ],
        },
      };
    },

    setIsMediaAccessesLoading(state, action: PayloadAction<boolean>) {
      state.isMediaAccessesLoading = action.payload;
    },

    resetState() {
      return initialState;
    },
  },
});

export const {
  setProfile,
  updateProfile,
  setContactMediaAccesses,
  updateMediaAccess,
  setIsMediaAccessesLoading,

  setLastActiveUserProfileInfo,

  resetState,
} = profileSlice.actions;

export const profile = profileSlice.reducer;
