import i18n from 'i18next';

export const getErrorMessageSafe = (
  err: any,
  prop = 'error'
): string | null => {
  return err?.response?.data?.[prop] || err?.response?.data?.message;
};

export const getErrorMessageWithDefault = (
  err: any,
  prop = 'message'
): string => {
  return (
    getErrorMessageSafe(err, prop) || i18n.t('common:errors.tryAgainLater')
  );
};

export const getErrorMessagesSafe = (
  err: any,
  prop = 'error'
): string[] | null => {
  return err?.response?.data?.[prop] || err?.response?.data?.message;
};

export const getErrorMessagesWithDefault = (
  err: any,
  prop = 'error'
): string[] => {
  return (
    getErrorMessagesSafe(err, prop) || [i18n.t('common:errors.tryAgainLater')]
  );
};
