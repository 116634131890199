import classNames from 'classnames';

import { MirrorService } from 'services/MirrorService';
import { AppDomain } from 'types/enums/AppDomain';

export const getThemeClassNames = (
  defaultClassName: string,
  themeClassNames: Record<string, string>
) => {
  return classNames(defaultClassName, themeClassNames[MirrorService.domain]);
};

export const MIRRORS_THEMES: Partial<Record<AppDomain, Record<string, any>>> = {
  [AppDomain.MariaDates]: {
    primary: '#FFFFFF',
    'primary-light': '#F5F5F5',
    secondary: '#5D5D5D',
    'secondary-light': '#E9E9E9',
    accent: '#FF5C00',
    'accent-light': '#FFDECC',
    danger: '#FF0000',
  },
  [AppDomain.PlaceForTalk]: {
    primary: '#FFFFFF',
    'primary-light': '#F5F5F5',
    secondary: '#5D5D5D',
    'secondary-light': '#E9E9E9',
    accent: '#7173F5',
    'accent-light': '#C9D1F7',
    danger: '#FF0000',
  },
  [AppDomain.LatinEsse]: {
    primary: '#FFFFFF',
    'primary-light': '#F5F5F5',
    secondary: '#5D5D5D',
    'secondary-light': '#E9E9E9',
    accent: '#177FE0',
    'accent-light': '#B7D3F3',
    danger: '#FF0000',
  },
  [AppDomain.EmberLove]: {
    primary: '#FFFFFF',
    'primary-light': '#F5F5F5',
    secondary: '#5D5D5D',
    'secondary-light': '#E9E9E9',
    accent: '#D34141',
    'accent-light': '#FFE6E6',
    danger: '#FF0000',
  },
};
