import React, { memo, PropsWithChildren, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';

import { usePreventBgScroll } from 'hooks/usePreventBgScroll';

import fadeTransition from 'styles/transitions/fade.module.sass';
import slideTransition from 'styles/transitions/slideToTop.module.sass';

import css from './popupMobile.module.sass';

interface Props {
  isOpen?: boolean;
  bodyClassName?: string;
  onClose?: () => void;
}

export const PopupMobile: React.FC<PropsWithChildren<Props>> = memo(
  ({ bodyClassName, isOpen, onClose, children }) => {
    const preventPropagation = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
      },
      []
    );

    usePreventBgScroll({ enabled: !!isOpen });

    return (
      <>
        <CSSTransition
          in={isOpen}
          appear
          timeout={300}
          classNames={slideTransition}
          unmountOnExit
        >
          <div className={css.root} onClick={onClose}>
            <div
              className={cx(css.container, bodyClassName)}
              onClick={preventPropagation}
            >
              {children}
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          in={isOpen}
          appear
          timeout={300}
          classNames={fadeTransition}
          unmountOnExit
        >
          <div className={css.overlay} />
        </CSSTransition>
      </>
    );
  }
);
