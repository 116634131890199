export enum EmailSubscriptionStatus {
  RegSend = 1,
  RegSkip = 2,
  RegError = 3,
  ConfirmationSend = 4,
  ConfirmationSkip = 5,
  MailConfirmed = 6,
  MailUnsubscribed = 7,
  RegDomainError = 8,
}
