import React, { memo } from 'react';

import { MirrorService } from 'services/MirrorService';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { Favicon } from 'components/shared/Favicon';

import css from './promotePWAPopup.module.sass';

interface Props {
  onSubmit: () => void;
  onClose: () => void;
}

export const PromotePopupContent: React.FC<Props> = memo(
  ({ onClose, onSubmit }) => {
    return (
      <div className={css.root}>
        <BaseButton
          type={ButtonTypes.Transparent}
          className={css.lineBtn}
          onClick={onClose}
        >
          <p className={css.line} />
        </BaseButton>

        <div className={css.header}>
          <div className={css.headerLeft}>
            <div className={css.favicon}>
              <Favicon className={css.faviconImage} />
            </div>
            <div className={css.siteInfo}>
              <p className={css.siteName}>{MirrorService.siteName}</p>
              <p className={css.siteDomain}>{MirrorService.domain}</p>
            </div>
          </div>
          <BaseButton
            type={ButtonTypes.Transparent}
            className={css.button}
            onClick={onSubmit}
          >
            Add to Home Screen
          </BaseButton>
        </div>
        <div className={css.main}>
          <p className={css.description}>
            Enhance your access! Add our icon to your home screen for quick,
            convenient access to our platform anytime, anywhere.
          </p>
          <div className={css.guide}>
            <div className={css.guideMock} />
            <div className={css.guideMock} />
            <div className={css.guideMock} />
            <div className={css.guideMock} />
            <div className={css.guideMock} />
            <div className={css.guideMock} />
            <div className={css.guideMock}>
              <Favicon className={css.guideMockImage} />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
