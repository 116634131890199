import { useQuery, UseQueryOptions } from 'react-query';

import { WizardApi, WizardFinderProfilesResponse } from 'api/WizardApi';

export const WIZARD_FINDER_QUERY_KEY = 'wizard-finder-query';

export const useWizardFinderProfilesQuery = (
  queryOptions?: UseQueryOptions<WizardFinderProfilesResponse>
) => {
  return useQuery<WizardFinderProfilesResponse>(
    WIZARD_FINDER_QUERY_KEY,
    WizardApi.getWizardFinderProfiles,
    {
      retry: false,
      select: (data) => ({
        ...data,
        data: (data?.data || []).map((item) => ({
          ...item,
          profiles_pack_hash: data?.profiles_pack_hash,
        })),
      }),
      cacheTime: 0,
      ...queryOptions,
    }
  );
};
