import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MirrorService } from 'services/MirrorService';
import { EmailSubscriptionStatus } from 'types/enums/EmailSubscriptionStatus';

import { setIsConfirmationResent } from 'helpers/emailConfirmation';
import { getErrorMessageWithDefault } from 'helpers/errors';
import {
  ConfirmationPopupName,
  ConfirmationPopupOperation,
  useEmailConfirmationPopupAnalytics,
} from 'hooks/analytics/useEmailConfirmationPopupAnalytics';
import { useEmailConfirmationMutation } from 'hooks/mutations/useEmailConfirmationMutation';
import { useWizardFinderProfilesQuery } from 'hooks/queries/useWizardFinderProfilesQuery';
import { useDeleteQueryByName } from 'hooks/useDeleteQueryByName';
import { QueryKeys } from 'hooks/useSystemSearchQueries';
import { useToast } from 'hooks/useToast';
import {
  getUserEmail,
  getUserEmailSubscriptionStatus,
} from 'store/auth/selectors';

import mailConfirmImg from 'assets/mail-confirm.png';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { Spinner, SpinnerTypes } from 'components/base/Spinner';
import { Popup } from 'components/shared/Popup';
import { UserAvatar } from 'components/shared/UserAvatar';

import css from './mailConfirmRegPopup.module.sass';

const MAX_USERS_PROFILES_COUNT = 4;

interface Props {
  onClose: () => void;
}

export const MailConfirmRegPopup: React.FC<Props> = ({ onClose }) => {
  const history = useHistory();

  const { showErrorToast, showSuccessToast } = useToast();
  const { trackEmailConfirmationPopup } = useEmailConfirmationPopupAnalytics();
  const { deleteQueryByName } = useDeleteQueryByName();

  const userEmail = useSelector(getUserEmail);
  const userEmailSubscriptionStatus = useSelector(
    getUserEmailSubscriptionStatus
  );

  const { data: userProfiles, isLoading: isProfilesLoading } =
    useWizardFinderProfilesQuery();

  const finalUserProfiles = useMemo(
    () => (userProfiles?.data || []).slice(0, MAX_USERS_PROFILES_COUNT),
    [userProfiles?.data]
  );

  const handleClose = useCallback(() => {
    deleteQueryByName(QueryKeys.StarterPopup);
    onClose();
  }, [deleteQueryByName, onClose]);

  const handleResentSuccess = useCallback(() => {
    setIsConfirmationResent();
    handleClose();
    showSuccessToast({ title: 'The mail has been resent!' });
  }, [handleClose, showSuccessToast]);

  const handleResentError = useCallback(
    (err: any) => {
      showErrorToast({
        title: getErrorMessageWithDefault(err),
      });
    },
    [showErrorToast]
  );

  const { mutate: sendConfirmation, isLoading } = useEmailConfirmationMutation({
    onError: handleResentError,
    onSuccess: handleResentSuccess,
  });

  const handleChangeMail = useCallback(() => {
    trackEmailConfirmationPopup({
      popupName: ConfirmationPopupName.ConfirmationAfterRegPopup,
      operation: ConfirmationPopupOperation.ClickChange,
    });

    handleClose();
    history.push('/my-profile/settings/account/change-email');
  }, [history, handleClose, trackEmailConfirmationPopup]);

  const handleSubmit = useCallback(() => {
    sendConfirmation();

    trackEmailConfirmationPopup({
      popupName: ConfirmationPopupName.ConfirmationAfterRegPopup,
      operation: ConfirmationPopupOperation.ClickAccept,
    });
  }, [sendConfirmation, trackEmailConfirmationPopup]);

  const handleCloseWithTrack = useCallback(() => {
    trackEmailConfirmationPopup({
      popupName: ConfirmationPopupName.ConfirmationAfterRegPopup,
      operation: ConfirmationPopupOperation.Close,
    });

    handleClose();
  }, [handleClose, trackEmailConfirmationPopup]);

  useEffect(() => {
    if (
      userEmail &&
      userEmailSubscriptionStatus !== EmailSubscriptionStatus.ConfirmationSend
    ) {
      handleClose();
    }
  }, [handleClose, userEmail, userEmailSubscriptionStatus]);

  useEffect(() => {
    trackEmailConfirmationPopup({
      popupName: ConfirmationPopupName.ConfirmationAfterRegPopup,
      operation: ConfirmationPopupOperation.Show,
    });
  }, [trackEmailConfirmationPopup]);

  return (
    <Popup
      bodyClassName={css.root}
      popupClassName={css.popup}
      onClose={handleCloseWithTrack}
    >
      <img src={mailConfirmImg} className={css.mailConfirmImg} alt="mail" />
      <h2 className={css.title}>Check Your Email</h2>
      <p className={css.subtitle}>
        Please confirm your email address to complete registration{' '}
        {!!userEmail && <b>{userEmail}</b>}
      </p>
      <BaseButton
        className={css.changeBtn}
        type={ButtonTypes.Transparent}
        disabled={isLoading}
        onClick={handleChangeMail}
      >
        Change Email
      </BaseButton>

      {isProfilesLoading && (
        <div className={css.usersLoader}>
          <Spinner type={SpinnerTypes.Dark} />
        </div>
      )}

      {!isProfilesLoading && !!finalUserProfiles.length && (
        <div className={css.users}>
          {finalUserProfiles.map((profileItem) => (
            <div className={css.avatarWrapper} key={profileItem?.ulid_id}>
              <UserAvatar
                className={css.userAvatar}
                size="66px"
                url={MirrorService.resolveImagePath(
                  profileItem.mainPhoto?.profile_url
                )}
              />
            </div>
          ))}
        </div>
      )}

      <BaseButton
        small
        className={css.submitBtn}
        type={ButtonTypes.Accent}
        loading={isLoading}
        onClick={handleSubmit}
      >
        Resend Email
      </BaseButton>
    </Popup>
  );
};
