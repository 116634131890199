import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePreventBgScroll } from 'hooks/usePreventBgScroll';
import {
  getActiveSystemPopupParamsSelector,
  getActiveSystemPopupTypeSelector,
} from 'store/systemPopup/selectors';
import { closeSystemPopup } from 'store/systemPopup/systemPopupSlice';

import { SYSTEM_POPUPS_MAP } from './constants';

export const SystemPopup = memo(() => {
  const dispatch = useDispatch();

  const activePopupType = useSelector(getActiveSystemPopupTypeSelector);
  const params = useSelector(getActiveSystemPopupParamsSelector);

  const handleCloseActivePopup = () => {
    dispatch(closeSystemPopup());
  };

  usePreventBgScroll({ enabled: !!activePopupType });

  if (!activePopupType || !params) {
    return null;
  }

  const ActivePopup = SYSTEM_POPUPS_MAP[activePopupType];

  return (
    <ActivePopup onClose={handleCloseActivePopup} params={params as any} />
  );
});
