import { useEffect } from 'react';

import { RouteEvent, RouteEventDetail } from 'helpers/routeEvents';

interface Props {
  onLoadStart?: (data: RouteEventDetail) => void;
  onLoadEnd?: (data: Required<RouteEventDetail>) => void;
}

export const useAppRouteEvents = ({ onLoadStart, onLoadEnd }: Props) => {
  useEffect(() => {
    const handleRouteLoadStart = (e: CustomEvent<RouteEventDetail>) => {
      onLoadStart && onLoadStart(e.detail);
    };

    const handleRouteLoadEnd = (e: CustomEvent<Required<RouteEventDetail>>) => {
      onLoadEnd && onLoadEnd(e.detail);
    };

    document.addEventListener(
      RouteEvent.LoadStart,
      handleRouteLoadStart as EventListener
    );
    document.addEventListener(
      RouteEvent.LoadEnd,
      handleRouteLoadEnd as EventListener
    );

    return () => {
      document.removeEventListener(
        RouteEvent.LoadStart,
        handleRouteLoadStart as EventListener
      );
      document.removeEventListener(
        RouteEvent.LoadEnd,
        handleRouteLoadEnd as EventListener
      );
    };
  }, [onLoadEnd, onLoadStart]);
};
