import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const useDeleteQueryByName = () => {
  const history = useHistory();

  const { search } = useLocation();

  const deleteQueryByName = useCallback(
    (queryKeyToDelete: string) => {
      const params = queryString.parse(search);

      delete params[queryKeyToDelete];

      history.replace({ search: queryString.stringify(params) });
    },
    [history, search]
  );

  return { deleteQueryByName };
};
