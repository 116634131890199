import React, { memo, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { TrackingApi } from 'api/TrackingApi';
import { getScreenOrientationWithSizes } from 'helpers/device';

import css from './adBlockDetector.module.sass';

export const AdBlockDetector: React.FC = memo(() => {
  const nodeRef = useRef<HTMLDivElement | null>(null);

  const [isActivated, setIsActivated] = useState(false);

  const isVisible = (elm: HTMLDivElement) => {
    if (!elm.offsetHeight && !elm.offsetWidth) {
      return false;
    }

    if (
      getComputedStyle(elm).visibility === 'hidden' ||
      getComputedStyle(elm).display === 'none'
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (!isActivated) {
      setTimeout(() => {
        setIsActivated(true);
      }, 300);
    }
  }, [isActivated]);

  useEffect(() => {
    if (isActivated) {
      const isAbBlockDetected = nodeRef.current && !isVisible(nodeRef.current);

      TrackingApi.trackAdBlockStatus({
        value: isAbBlockDetected ? 1 : 0,
        screen: getScreenOrientationWithSizes(),
      });
    }
  }, [isActivated]);

  return (
    <div
      ref={nodeRef}
      className={cx(
        css.root,
        'ad ads adv advert advertisement ad-zone banner banners textads banner-ads banner_ads ad-unit ad-zone ad-space adsbox'
      )}
    >
      &nbsp;
    </div>
  );
});
