import { useCallback } from 'react';

import { TrackingApi } from 'api/TrackingApi';

export const usePWAAnalytics = () => {
  const trackPWAShow = useCallback((name: string) => {
    TrackingApi.trackPopup({
      name,
      operation: 'show',
      url: window.location.href,
    }).catch();
  }, []);

  const trackPWAClose = useCallback((name: string) => {
    TrackingApi.trackPopup({
      name,
      operation: 'close',
      url: window.location.href,
    }).catch();
  }, []);

  const trackPWAClick = useCallback((name: string) => {
    TrackingApi.trackPopup({
      name,
      operation: 'click',
      url: window.location.href,
    }).catch();
  }, []);

  return {
    trackPWAShow,
    trackPWAClose,
    trackPWAClick,
  };
};
