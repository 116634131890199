import { ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';

type PropTypes = {
  children: ReactElement;
};

export const Desktop: React.FC<PropTypes> = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1280 });

  return isDesktop ? children : null;
};

export const Tablet: React.FC<PropTypes> = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 });

  return isTablet ? children : null;
};

export const Mobile: React.FC<PropTypes> = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return isMobile ? children : null;
};

export const Default: React.FC<PropTypes> = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  return isNotMobile ? children : null;
};
