import { UserCard } from 'types/interfaces/user/UserCard';

import { httpClient } from './httpClient';

export interface WizardAnswer {
  id: number;
  answer: string;
}

export interface WizardStepResponse {
  id: number;
  answers: WizardAnswer[];
  detail: string | null;
  text?: string;
}

export interface WizardFinderProfilesResponse {
  data: UserCard[];
  profiles_pack_hash?: string;
}

export const WizardApi = {
  async getWizardSteps() {
    const { data } = await httpClient.get<WizardStepResponse[]>('/wizard');

    return data;
  },

  async getWizardFinderProfiles() {
    const { data } =
      await httpClient.get<WizardFinderProfilesResponse>('/wizard/finder');

    return data;
  },

  async saveWizardAnswer({
    stepId,
    answer,
    special,
  }: {
    stepId: number;
    answer: number[];
    special?: Record<string, number>;
  }) {
    const { data } = await httpClient.post<WizardStepResponse[]>(
      '/wizard/answer',
      {
        wizard_id: stepId,
        answer,
        ...(!!special && { special }),
      }
    );

    return data;
  },

  async wizardFinish() {
    const { data } = await httpClient.get('/wizard/finish');

    return data;
  },
};
