import { useCallback } from 'react';

import { TrackingApi } from 'api/TrackingApi';

const STARTER_POPUP_NAME = '20_free_credits';

export const useStarterPopupAnalytics = () => {
  const trackStarterPopupShow = useCallback(() => {
    TrackingApi.trackPopup({
      name: STARTER_POPUP_NAME,
      operation: 'show',
      url: window.location.href,
    }).catch();
  }, []);

  const trackStarterPopupClose = useCallback(() => {
    TrackingApi.trackPopup({
      name: STARTER_POPUP_NAME,
      operation: 'close',
      url: window.location.href,
    }).catch();
  }, []);

  const trackStarterPopupClick = useCallback(() => {
    TrackingApi.trackPopup({
      name: STARTER_POPUP_NAME,
      operation: 'click',
      url: window.location.href,
    }).catch();
  }, []);

  return {
    trackStarterPopupShow,
    trackStarterPopupClose,
    trackStarterPopupClick,
  };
};
