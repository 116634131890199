import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  QueryKeys,
  useSystemSearchQueries,
} from 'hooks/useSystemSearchQueries';

export const useClickIdAssign = () => {
  const { search } = useLocation();
  const history = useHistory();

  const { clickIdQuery, fbclidQuery, ttclidQuery, twclidQuery, gclidQuery } =
    useSystemSearchQueries();

  useEffect(() => {
    const mathcingParam =
      fbclidQuery.value ||
      ttclidQuery.value ||
      twclidQuery.value ||
      gclidQuery.value;

    if (
      mathcingParam &&
      !clickIdQuery.value &&
      !search.includes(QueryKeys.ClickId)
    ) {
      history.replace({
        search: `${search}&${QueryKeys.ClickId}=${mathcingParam}`,
      });
    }
  }, [
    clickIdQuery.value,
    fbclidQuery.value,
    gclidQuery.value,
    history,
    search,
    ttclidQuery.value,
    twclidQuery.value,
  ]);
};
