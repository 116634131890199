import { MailFormat } from 'types/enums/mails/MailFormat';
import { Mail } from 'types/interfaces/mails/Mail';
import { MailsChain } from 'types/interfaces/mails/MailsChain';
import { MailsChainsFilters } from 'types/interfaces/mails/MailsChainsFilters';
import { UserContact } from 'types/interfaces/user/UserContact';

import { httpClient } from './httpClient';

interface MailsChainResponse {
  data: MailsChain[];
  next: string | null;
}

interface MailsResponse {
  next: string | null;
  contact: UserContact;
  inmails: Mail[];
  is_liked: boolean;
}

interface SendTextMailsMessagePayload {
  contactId: string;
  body: string;
  photosIds: number[];
}

const readIncomeMails = (message: Mail) => {
  if (!message.is_incoming) {
    return message;
  }

  return {
    ...message,
    read_at: new Date().toUTCString(),
  };
};

export const MailsApi = {
  async fetchMailsChains(filters: MailsChainsFilters) {
    const { data } = await httpClient.get<MailsChainResponse>(`/inmails`, {
      params: filters,
    });

    return data;
  },

  async fetchMoreMailsChains(nextUrl: string) {
    const { data } = await httpClient.get<MailsChainResponse>(nextUrl);

    return data;
  },

  async fetchMails(contactId: string) {
    const { data } = await httpClient.get<MailsResponse>(
      `/inmails/${contactId}`
    );

    return {
      ...data,
      inmails: data.inmails?.map(readIncomeMails) || [],
    };
  },

  async fetchMoreMails(url: string) {
    const { data } = await httpClient.get<MailsResponse>(url);

    return data;
  },

  async sendTextMail(payload: SendTextMailsMessagePayload) {
    return httpClient.post<{ message: Mail }>('/inmails', {
      type: MailFormat.Text,
      body: payload.body,
      receiver_id: payload.contactId,
      photos: payload.photosIds,
    });
  },

  async getMailAccess(payload: { mailId: number }) {
    return httpClient.post<{ data: Mail }>('/inmail/access', {
      inmail_id: payload.mailId,
    });
  },

  async retrySendMail(mailId: number) {
    const { data } = await httpClient.post<{ data: Mail }>(
      `/inmail/${mailId}/retry`
    );

    return data;
  },
};
