import axiosRetry from 'axios-retry';

import { MirrorService } from 'services/MirrorService';

import { httpClient } from './httpClient';

export const VisitsApi = {
  async getTranslationBuildId() {
    return httpClient.get('/visit');
  },

  fetchInitialIdFromFirstDomain(traceId?: string) {
    axiosRetry(httpClient, {
      retries: 3,
      retryDelay: () => 100,
    });

    return httpClient.get<{ initial_id: string }>(
      `${MirrorService.originForInitialId}/get-initial-id`,
      {
        params: {
          trace_id: traceId,
        },
      }
    );
  },

  fetchInitialIdFromServiceDomain(initialId?: string) {
    axiosRetry(httpClient, {
      retries: 3,
      retryDelay: () => 100,
    });

    return httpClient.get<{ initial_id: string }>(
      `${MirrorService.serviceApiUrl}/get-initial-id`,
      {
        params: {
          initial_id: initialId,
        },
      }
    );
  },
};
