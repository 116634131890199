import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';

import { SystemPopupTypes } from './systemPopupSlice';

export const getActiveSystemPopupTypeSelector = (state: RootState) =>
  state.systemPopup.type;

export const getActiveSystemPopupParamsSelector = (state: RootState) => ({
  ...(state.systemPopup.params || {}),
});

export const getIsActiveLowBalancePopupSelector = createSelector(
  getActiveSystemPopupTypeSelector,
  (activePopupType) => activePopupType === SystemPopupTypes.LowBalance
);
