import React, { memo } from 'react';

import { ToastTypes } from 'types/enums/ToastTypes';

import { ReactComponent as NotificationErrorIcon } from 'assets/icons/notification-error.svg';
import { ReactComponent as NotificationSuccessIcon } from 'assets/icons/notification-success.svg';
import { ReactComponent as NotificationWarningIcon } from 'assets/icons/notification-warning.svg';

import css from './systemToast.module.sass';

export interface SystemToastProps {
  type?: Exclude<ToastTypes, ToastTypes.Notification>;
  title: string;
  subtitle?: string;
  onClick?: () => void;
}

const ICONS_MAP = {
  [ToastTypes.Success]: NotificationSuccessIcon,
  [ToastTypes.Warning]: NotificationWarningIcon,
  [ToastTypes.Error]: NotificationErrorIcon,
};

export const SystemToast: React.FC<SystemToastProps> = memo(
  ({ type, title, subtitle, onClick }) => {
    const Icon = type ? ICONS_MAP[type] : NotificationSuccessIcon;

    return (
      <div className={css.root} onClick={onClick}>
        <Icon className={css.icon} />

        <div className={css.content}>
          <p className={css.title}>{title}</p>
          {subtitle && <p className={css.subtitle}>{subtitle}</p>}
        </div>
      </div>
    );
  }
);
