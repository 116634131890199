import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getUserIdFromCookies } from 'helpers/userId';
import { getUser } from 'store/auth/selectors';

import { useSystemSearchQueries } from './useSystemSearchQueries';

export const useUserId = () => {
  const currentUser = useSelector(getUser);

  const { userIdQuery } = useSystemSearchQueries();

  const userIdCookieValue = useMemo(() => getUserIdFromCookies(), []);

  const userId = useMemo(() => {
    if (currentUser?.ulid_id) return String(currentUser?.ulid_id);
    if (userIdQuery.value) return userIdQuery.value;
    if (userIdCookieValue) return String(userIdCookieValue);

    return undefined;
  }, [currentUser?.ulid_id, userIdCookieValue, userIdQuery.value]);

  return { userId, userIdQueryValue: userIdQuery.value, userIdCookieValue };
};
