import { AppDispatch } from 'store';

import { getAccessTokenDataFromStorage } from 'helpers/auth';
import { setIsAuthenticated } from 'store/auth/authSlice';
import { logoutThunk } from 'store/auth/thunks';

import { httpClient } from './httpClient';
import { AccessTokenData, RefreshTokenApi } from './RefreshTokenApi';

const ROUTES_TO_IGNORE_NOT_AUTHORIZED = ['/refresh', '/logout'];

let refreshingRequest: Promise<AccessTokenData> | null = null;

export const setupInterceptors = ({ dispatch }: { dispatch: AppDispatch }) => {
  httpClient.interceptors.request.use(async (config) => {
    const { expire, token } = getAccessTokenDataFromStorage();

    // eslint-disable-next-line no-param-reassign
    if (token) config.headers.Authorization = `Bearer ${token}`;

    try {
      if (token && (!expire || expire < Date.now())) {
        if (!refreshingRequest)
          refreshingRequest = RefreshTokenApi.refreshAccessToken({ token });

        const { expires_in, access_token } = await refreshingRequest;

        dispatch(
          setIsAuthenticated({
            accessToken: access_token,
            expire: expires_in,
            isAuthenticated: true,
          })
        );

        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${access_token}`;
        refreshingRequest = null;
      }

      return config;
    } catch {
      refreshingRequest = null;

      return config;
    }
  });

  httpClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const status = error?.response?.status;

      if (
        status === 401 &&
        !ROUTES_TO_IGNORE_NOT_AUTHORIZED.find(
          (routeItem) =>
            error?.response?.request?.responseURL?.includes(routeItem)
        )
      ) {
        dispatch(logoutThunk({ withApi: false }));

        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );
};
