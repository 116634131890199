import React, { useEffect } from 'react';
import { Message } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import cx from 'classnames';

import styles from './baseTextarea.module.sass';

type PropTypes = {
  value?: string;
  maxRows?: number;
  minRows?: number;
  name: string;
  placeholder?: string;
  maxSymbols?: number;
  isSymbolsCounterHidden?: boolean;
  className?: string;
  disabled?: boolean;
  error?: string | Message;
  autoFocus?: boolean;
  onHeightChange?: (height: number) => void;
  onChange?: (newValue: string) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const BaseTextarea = React.forwardRef<HTMLTextAreaElement, PropTypes>(
  (
    {
      value,
      maxRows,
      minRows,
      name,
      placeholder,
      maxSymbols,
      isSymbolsCounterHidden = false,
      className,
      disabled,
      error,
      autoFocus,
      onHeightChange,
      onChange,
      onKeyPress,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    const inputHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value: newValue } = event.target;

      if (
        value &&
        maxSymbols &&
        value.length === maxSymbols &&
        newValue.length >= value.length
      ) {
        return;
      }

      if (maxSymbols && newValue.length >= maxSymbols) {
        onChange && onChange(newValue.slice(0, maxSymbols));

        return;
      }

      onChange && onChange(newValue);
    };

    const symbolsLeftTextShown = maxSymbols && !isSymbolsCounterHidden;

    useEffect(() => {
      if (autoFocus && ref) {
        // @ts-ignore
        ref.current?.focus();
      }
    }, [autoFocus, ref]);

    return (
      <div className={styles.root}>
        <TextareaAutosize
          value={value}
          name={name}
          placeholder={placeholder}
          maxRows={maxRows}
          minRows={minRows}
          ref={ref}
          className={cx(styles.textarea, className, {
            [styles.textareaWithCounter]: !isSymbolsCounterHidden,
          })}
          disabled={disabled}
          onHeightChange={onHeightChange}
          onChange={inputHandler}
          onKeyPress={onKeyPress}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        {symbolsLeftTextShown && (
          <div className={styles.maxSymbols}>
            {maxSymbols - (value?.length || 0)}
          </div>
        )}

        {error && (
          <div
            className={cx(styles.errorText, {
              [styles.noSymbolsLeftText]: !symbolsLeftTextShown,
            })}
          >
            {error}
          </div>
        )}
      </div>
    );
  }
);
