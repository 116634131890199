import { useEffect, useState } from 'react';

import { MirrorService } from 'services/MirrorService';

import { TrackingApi } from 'api/TrackingApi';
import { Gtm } from 'helpers/analytics/gtm';
import { setBuildVersionToSessionStorage, useI18n } from 'hooks/useI18n';
import { useInitialId } from 'hooks/useInitialId';
import { useSessionStartTrack } from 'hooks/useSessionStartTrack';

export const useInitializeApp = () => {
  const { getInitialId, setInitialIdToCookie } = useInitialId();
  const { initI18n } = useI18n();
  const { trackStartSession } = useSessionStartTrack();

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      if (MirrorService.gtmId) Gtm.init({ gtmId: MirrorService.gtmId });

      try {
        const initialId = await getInitialId();

        setInitialIdToCookie(initialId);
      } catch (err: any) {
        TrackingApi.trackError({
          type: 'error',
          message: `set initial_id fail: ${err?.message}`,
        });
      }

      const newTranslationsBuildVersion = await trackStartSession(true);

      if (newTranslationsBuildVersion) {
        setBuildVersionToSessionStorage(newTranslationsBuildVersion);
      }

      await initI18n();

      setIsInitialized(true);
    };

    initialize();
  }, [getInitialId, initI18n, setInitialIdToCookie, trackStartSession]);

  return {
    isInitialized,
  };
};
