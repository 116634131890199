import { useCallback } from 'react';
import queryString from 'query-string';

import { setXRefererUrl } from 'api/httpClient';
import { useLocationChange } from 'hooks/useLocationChange';

import { QueryKeys } from './useSystemSearchQueries';

export const useXRefererUrl = () => {
  const handleSetXRefererUrl = useCallback(
    ({ currentUrl }: { currentUrl: string }) => {
      const {
        [QueryKeys.Fbclid]: fbclid,
        [QueryKeys.Ttclid]: ttclid,
        [QueryKeys.Twclid]: twclid,
        [QueryKeys.Gclid]: gclid,
        [QueryKeys.ClickId]: clickId,
      } = queryString.parse(currentUrl);

      let finalXReferer = currentUrl;

      const mathcingParam = fbclid || ttclid || twclid || gclid;

      if (mathcingParam && !clickId) {
        finalXReferer = `${finalXReferer}&${QueryKeys.ClickId}=${mathcingParam}`;
      }

      setXRefererUrl(finalXReferer);
    },
    []
  );

  useLocationChange(handleSetXRefererUrl);
};
