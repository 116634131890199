import { Photo } from 'types/interfaces/Photo';
import { Video } from 'types/interfaces/Video';

export const hasProperty = (obj: object, prop: string | number | symbol) =>
  Object.prototype.hasOwnProperty.call(obj, prop);

export const assertNotNullObject = (prop: unknown): prop is object =>
  typeof prop === 'object' && prop !== null;

export const assertString = (prop: unknown): prop is string =>
  typeof prop === 'string';

export const assertStringArray = (prop: unknown): prop is string[] =>
  Array.isArray(prop) && prop.every(assertString);

export const assertNumber = (prop: unknown): prop is number => {
  if (typeof prop !== 'number') {
    return false;
  }

  if (prop !== Number(prop)) {
    return false;
  }

  if (prop === Infinity) {
    return false;
  }

  return true;
};

export const assertNumberArray = (prop: unknown): prop is number[] => {
  return Array.isArray(prop) && prop.every(assertNumber);
};

export const assertUserPhotoMedia = (file: Photo | Video): file is Photo => {
  return (
    (file as Photo).big_url !== undefined &&
    (file as Photo).profile_url !== undefined &&
    (file as Photo).is_main !== undefined
  );
};
