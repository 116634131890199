import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MailsTab } from 'types/enums/mails/MailsTab';
import { Mail } from 'types/interfaces/mails/Mail';
import { MailsChain } from 'types/interfaces/mails/MailsChain';
import { MailsChainsFilters } from 'types/interfaces/mails/MailsChainsFilters';
import { Photo } from 'types/interfaces/Photo';
import { UserContact } from 'types/interfaces/user/UserContact';

const DEFAULT_MAILS_FILTERS: MailsChainsFilters = {
  tab: MailsTab.All,
};

type MessengerState = {
  mailsFilters: MailsChainsFilters;
  mailsChains: MailsChain[];
  mailsChainsLoading: boolean;
  nextMailsChains: string | null;

  mails: Record<
    string,
    {
      next: string | null;
      isLiked: boolean;
      contact: UserContact;
      mails: Mail[];
    }
  >;
  mailsInitLoading: boolean;

  loadingMailIds: number[];
};

const initialState: MessengerState = {
  mailsFilters: DEFAULT_MAILS_FILTERS,
  mailsChains: [],
  nextMailsChains: null,
  mailsChainsLoading: true,

  mails: {},
  mailsInitLoading: true,

  loadingMailIds: [],
};

const updateMailsChainWithNewMailMapper =
  (newMail: Mail, contactId: string) => (mailsChain: MailsChain) => {
    const isDialogWithProperContact = mailsChain.contact.ulid_id === contactId;

    if (!isDialogWithProperContact) {
      return mailsChain;
    }

    return {
      ...mailsChain,
      is_incoming: newMail.is_incoming,
      sent_at: newMail.sent_at,
      status: newMail.status,
      body: newMail.body,
      unread_count: newMail.is_incoming ? mailsChain.unread_count + 1 : 0,
    };
  };

const mailsSlice = createSlice({
  name: 'mails',
  initialState,
  reducers: {
    // ? ***************** MAILS  ACTIONS START *****************
    setMailsChains(state, action: PayloadAction<MailsChain[]>) {
      state.mailsChains = action.payload;
    },

    setNextMailsChains(state, action: PayloadAction<string | null>) {
      state.nextMailsChains = action.payload;
    },

    setMailsChainsLoading(state, action: PayloadAction<boolean>) {
      state.mailsChainsLoading = action.payload;
    },

    setMailsChainsFilters(
      state,
      action: PayloadAction<Partial<MailsChainsFilters>>
    ) {
      state.mailsFilters = { ...state.mailsFilters, ...action.payload };
    },

    addMailsChains(state, action: PayloadAction<MailsChain[]>) {
      state.mailsChains = [...action.payload, ...state.mailsChains];
    },

    updateMailsChainWithNewMail(
      state,
      action: PayloadAction<{ message: Mail; contactId: string }>
    ) {
      const { message: newMail, contactId } = action.payload;

      const isExistInMailsChain = Boolean(
        state.mailsChains.find(
          (mailsChain) => mailsChain.contact.ulid_id === contactId
        )
      );

      if (isExistInMailsChain) {
        state.mailsChains = state.mailsChains.map(
          updateMailsChainWithNewMailMapper(newMail, contactId)
        );
      }
    },

    // ? ***************** MAILS CHAINS ACTIONS *****************

    // ? ***************** MAILS ACTIONS *****************
    setMails(
      state,
      action: PayloadAction<{
        isLiked: boolean;
        contact: UserContact;
        next: string | null;
        mails: Mail[];
      }>
    ) {
      const { mails, contact, isLiked, next } = action.payload;

      state.mails[contact.ulid_id] = {
        next,
        isLiked,
        contact,
        mails,
      };
    },

    setMailsInitLoading(state, action: PayloadAction<boolean>) {
      state.mailsInitLoading = action.payload;
    },

    addMail(
      state,
      action: PayloadAction<{ message: Mail; contactId: string }>
    ) {
      const { message, contactId } = action.payload;

      if (state.mails[contactId] && state.mails[contactId].mails) {
        let isMailAlreadyExist = false;

        const newMails = state.mails[contactId].mails.map((messageItem) => {
          if (messageItem.id === message.id) {
            isMailAlreadyExist = true;

            return message;
          }
          return messageItem;
        });

        state.mails[contactId].mails = isMailAlreadyExist
          ? newMails
          : [...newMails, message];
      } else {
        state.mails[contactId] = {
          ...state.mails[contactId],
          mails: [message],
          next: null,
        };
      }
    },

    updateMail(
      state,
      action: PayloadAction<{ mail: Mail; contactId: string }>
    ) {
      const { mail, contactId } = action.payload;

      if (state.mails[contactId] && state.mails[contactId].mails) {
        state.mails[contactId].mails = state.mails[contactId].mails.map(
          (oldMail) => {
            return oldMail.id === mail.id ? mail : oldMail;
          }
        );
      } else {
        state.mails[contactId] = {
          ...state.mails[contactId],
          mails: [mail],
          next: null,
        };
      }
    },

    grantAccessToMail(
      state,
      action: PayloadAction<{ mailId: number; contactId: string }>
    ) {
      const { mailId, contactId } = action.payload;

      if (state.mails[contactId] && state.mails[contactId].mails) {
        state.mails[contactId].mails = state.mails[contactId].mails.map(
          (mailItem) => {
            if (mailItem.id === mailId) return { ...mailItem, paid: true };

            return mailItem;
          }
        );
      }

      state.mailsChains = state.mailsChains.map((mailsChainsItem) => {
        if (mailsChainsItem.contact.ulid_id === contactId)
          return {
            ...mailsChainsItem,
            unread_count:
              mailsChainsItem.unread_count > 0
                ? mailsChainsItem.unread_count - 1
                : 0,
          };

        return mailsChainsItem;
      });
    },

    addMails(
      state,
      action: PayloadAction<{
        mails: Mail[];
        contactId: string;
        next: string | null;
      }>
    ) {
      const { mails, contactId, next } = action.payload;

      const allMails = state.mails[contactId].mails.concat(mails);

      state.mails[contactId] = {
        ...state.mails[contactId],
        mails: allMails,
        next,
      };
    },

    updateMailsPhotoSources(
      state,
      action: PayloadAction<{
        contactId: string;
        photo: Photo;
      }>
    ) {
      const { contactId, photo } = action.payload;

      const allMails = state.mails?.[contactId]?.mails;

      if (!allMails) return;

      state.mails[contactId].mails = allMails.map((mail) => {
        if (mail.photos?.find((photoItem) => photoItem.id === photo.id)) {
          return {
            ...mail,
            photos: mail.photos.map((photoItem) => {
              if (photoItem.id === photo.id) {
                return {
                  ...photoItem,
                  ...photo,
                };
              }

              return photoItem;
            }),
          };
        }

        return mail;
      });
    },

    // ? ***************** MAILS ACTIONS *****************

    // ? ***************** CONTACT ACTIONS *****************
    likeMailsChainContact(
      state,
      action: PayloadAction<{
        contactId: string;
      }>
    ) {
      const { contactId } = action.payload;

      if (state.mails[contactId]) state.mails[contactId].isLiked = true;
    },
    // ? ***************** CONTACT ACTIONS *****************

    setLoadingMails(state, action: PayloadAction<number>) {
      if (state.loadingMailIds.includes(action.payload)) {
        state.loadingMailIds = state.loadingMailIds.filter(
          (loadingItem) => loadingItem !== action.payload
        );

        return;
      }

      state.loadingMailIds = [...state.loadingMailIds, action.payload];
    },

    resetState() {
      return initialState;
    },
  },
});

export const {
  setMailsChains,
  setNextMailsChains,
  setMailsChainsLoading,
  addMailsChains,
  setMailsChainsFilters,

  setMails,
  setMailsInitLoading,
  addMail,
  updateMailsPhotoSources,

  updateMail,
  grantAccessToMail,
  updateMailsChainWithNewMail,
  addMails,

  likeMailsChainContact,

  setLoadingMails,

  resetState,
} = mailsSlice.actions;

export const inbox = mailsSlice.reducer;
