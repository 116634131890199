import { useCallback, useContext } from 'react';

import { PWAContext } from 'contexts/PWAContext';

export enum PWAPromotionPages {
  MyProfile = 'myProfile',
}

export const usePromotionPWA = () => {
  const {
    isCanPromotePWA,
    handlePWAInstalled,
    handleShowNativePromptDialogPWA,
  } = useContext(PWAContext);

  const handlePWAPromotionButtonSubmit = useCallback(
    (name: string) => {
      handleShowNativePromptDialogPWA({
        popupName: name,
        onSubmit: handlePWAInstalled,
      });
    },
    [handlePWAInstalled, handleShowNativePromptDialogPWA]
  );

  return {
    isCanPromotePWA,
    handlePWAPromotionButtonSubmit,
  };
};
