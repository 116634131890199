import { useCallback, useEffect, useRef } from 'react';

import { MirrorService } from 'services/MirrorService';

import { AuthApi, OnlineType } from 'api/AuthApi';
import { getAccessTokenDataFromStorage } from 'helpers/auth';

import { useInactiveBrowserTab } from './useInactiveBrowserTab';

const PING_TIMEOUT = 60 * 1000;

export const useOnlinePingV2 = (isAuthenticated: boolean) => {
  const lastPing = useRef<number>(0);

  const { isBrowserTabActive } = useInactiveBrowserTab();

  const handlePingOnlineSend = useCallback(() => {
    if (!isAuthenticated) return;

    const eventTs = Date.now();

    if (eventTs - lastPing.current > PING_TIMEOUT) {
      lastPing.current = eventTs;

      AuthApi.pingOnlineV2(OnlineType.RealOnline);
    }
  }, [isAuthenticated]);

  const handlePingOfflineSend = useCallback(() => {
    if (!isAuthenticated) return;

    if (lastPing.current) {
      lastPing.current = 0;

      AuthApi.pingOnlineV2(OnlineType.RealOffline);
    }
  }, [isAuthenticated]);

  const handleBeaconOfflineSend = useCallback(() => {
    if (!isAuthenticated || !lastPing.current) return;

    lastPing.current = 0;

    const { token: accessToken } = getAccessTokenDataFromStorage();

    if (!accessToken) return;

    try {
      const blob = new Blob(
        [
          JSON.stringify({
            type: 4,
          }),
        ],
        {
          type: 'application/json',
        }
      );

      navigator.sendBeacon(
        `${MirrorService.apiUrl}/ping/v2?token=${accessToken}`,
        blob
      );
    } catch (error) {
      console.error(error);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isBrowserTabActive) {
      handlePingOfflineSend();
    }
  }, [handlePingOfflineSend, isBrowserTabActive]);

  useEffect(() => {
    if (isBrowserTabActive) {
      handlePingOnlineSend();
    }
  }, [handlePingOnlineSend, isBrowserTabActive]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeaconOfflineSend);

    return () => {
      window.removeEventListener('beforeunload', handleBeaconOfflineSend);
    };
  }, [handleBeaconOfflineSend]);

  useEffect(() => {
    document.addEventListener('click', handlePingOnlineSend);
    document.addEventListener('scroll', handlePingOnlineSend);
    document.addEventListener('mousemove', handlePingOnlineSend);
    document.addEventListener('keydown', handlePingOnlineSend);
    document.addEventListener('touchstart', handlePingOnlineSend);

    return () => {
      document.removeEventListener('click', handlePingOnlineSend);
      document.removeEventListener('scroll', handlePingOnlineSend);
      document.removeEventListener('mousemove', handlePingOnlineSend);
      document.removeEventListener('keydown', handlePingOnlineSend);
      document.removeEventListener('touchstart', handlePingOnlineSend);
    };
  }, [handlePingOnlineSend]);
};
