import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Photo } from 'types/interfaces/Photo';
import { User } from 'types/interfaces/user/User';
import { UserEmailInfo } from 'types/interfaces/user/UserEmailInfo';

import {
  getAccessTokenDataFromStorage,
  removeAccessTokenFromStorage,
  setAccessTokenDataToStorage,
} from 'helpers/auth';

type AuthUser = User & UserEmailInfo;

interface AuthState {
  user: AuthUser | null;
  loading: boolean;
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: !!getAccessTokenDataFromStorage().token,
  loading: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<AuthUser | null>) {
      state.user = action.payload;
    },
    setAuthLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setIsAuthenticated(
      state,
      action: PayloadAction<{
        isAuthenticated: boolean;
        accessToken: string | null;
        expire: number | null;
      }>
    ) {
      const { accessToken, expire } = action.payload;

      if (accessToken) {
        setAccessTokenDataToStorage({ expire, token: accessToken });
      } else {
        removeAccessTokenFromStorage();
      }

      state.isAuthenticated = !!action.payload.isAuthenticated;
    },
    updateUser(state, action: PayloadAction<Partial<AuthUser>>) {
      if (state.user) {
        state.user = {
          ...state.user,
          ...action.payload,
        };
      }
    },
    addUserPhoto(state, action: PayloadAction<Photo>) {
      if (state.user) {
        state.user = {
          ...state.user,
          photos: [...(state.user.photos || []), action.payload],
        };
      }
    },
  },
});

export const {
  setUser,
  setIsAuthenticated,
  setAuthLoading,
  updateUser,
  addUserPhoto,
} = authSlice.actions;

export const auth = authSlice.reducer;
