import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { TrackingApi } from 'api/TrackingApi';
import { useAppMeta } from 'hooks/useAppMeta';
import { useAuthInterceptors } from 'hooks/useAuthInterceptors';
import { useClickIdAssign } from 'hooks/useClickIdAssign';
import { useDeviceRotation } from 'hooks/useDeviceRotation';
import { useInitializeApp } from 'hooks/useInitializeApp';
import { useMirrorThemeColors } from 'hooks/useMirrorTheme';
import { useOnlinePing } from 'hooks/useOnlinePing';
import { useOnlinePingV2 } from 'hooks/useOnlinePingV2';
import { usePageLoad } from 'hooks/usePageLoad';
import { useRetryConversions } from 'hooks/useRetryConversions';
import { useScrollRestoration } from 'hooks/useScrollRestoration';
import { useStarterPopup } from 'hooks/useStarterPopup';
import { useUpdateBodyHeightOnResize } from 'hooks/useUpdateBodyHeightOnResize';
import { useXPrevUrl } from 'hooks/useXPrevUrl';
import { useXRefererUrl } from 'hooks/useXRefererUrl';
import { getIsAuthenticated } from 'store/auth/selectors';
import { setPrevPagePathname } from 'store/common/commonSlice';
import { getActiveSystemPopupTypeSelector } from 'store/systemPopup/selectors';

import { MailConfirmationNotification } from 'components/sections/MailConfirmation/MailConfirmationNotification';
import { MailConfirmationPopup } from 'components/sections/MailConfirmation/MailConfirmationPopup';
import { SystemPopup } from 'components/sections/SystemPopups';
import { AdBlockDetector } from 'components/shared/AdBlockDetector';
import { FullscreenSpinner } from 'components/shared/FullscreenSpinner';
import { ZendeskWidget } from 'components/shared/ZendeskWidget';

import { Routes } from './Routes';

export const App = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const isAuthenticated = useSelector(getIsAuthenticated);
  const activeSystemPopupType = useSelector(getActiveSystemPopupTypeSelector);

  useAuthInterceptors();
  useClickIdAssign();
  useXPrevUrl();
  useXRefererUrl();

  const { isInitialized } = useInitializeApp();

  useRetryConversions(!!isAuthenticated);
  useOnlinePing(!!isAuthenticated);
  useOnlinePingV2(!!isAuthenticated);
  useStarterPopup();
  usePageLoad();

  useAppMeta();
  useMirrorThemeColors();
  useUpdateBodyHeightOnResize();
  useScrollRestoration();
  useDeviceRotation();

  useEffect(() => {
    if (isInitialized) TrackingApi.trackAppLoadEnd();
  }, [isInitialized]);

  useEffect(() => {
    return () => {
      dispatch(setPrevPagePathname(pathname));
    };
  }, [dispatch, pathname]);

  if (!isInitialized) {
    return <FullscreenSpinner />;
  }

  return (
    <>
      <AdBlockDetector />
      <MailConfirmationNotification />
      <MailConfirmationPopup />

      {isAuthenticated && <ZendeskWidget />}
      {activeSystemPopupType && <SystemPopup />}

      <Routes />
    </>
  );
};
