import React, { memo, ReactElement, useMemo } from 'react';
import cx from 'classnames';

import { UserGender } from 'types/enums/UserGender';

import { getPhotoPlaceholder } from 'helpers/photoPlaceholder';

import css from './userAvatar.module.sass';

interface Props {
  url?: string | null;
  gender?: UserGender;
  isOnline?: boolean;
  className?: string;
  size?: string;
  shouldShowOnlineBadge?: boolean;
  badge?: ReactElement;
}

export const UserAvatar: React.FC<Props> = memo(
  ({
    url,
    gender,
    isOnline,
    className = '',
    size = '100px',
    shouldShowOnlineBadge = false,
    badge,
  }) => {
    const safeUrl = useMemo(
      () => url || getPhotoPlaceholder(gender),
      [gender, url]
    );

    return (
      <div
        className={cx(css.root, className)}
        style={{
          width: size,
          minWidth: size,
          height: size,
          backgroundImage: `url(${safeUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center 20%',
          aspectRatio: '1/1',
        }}
      >
        {Boolean(badge) && <div className={css.badge}>{badge}</div>}

        {shouldShowOnlineBadge && !badge && (
          <p className={cx(css.offline, { [css.online]: isOnline })} />
        )}
      </div>
    );
  }
);
