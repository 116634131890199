import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setupInterceptors } from 'api/httpInterceptors';

export const useAuthInterceptors = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    setupInterceptors({ dispatch });
  }, [dispatch]);
};
