import { useCallback, useEffect, useRef } from 'react';

import { AuthApi } from 'api/AuthApi';
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'helpers/sessionStorage';

const PING_TIMEOUT = 60 * 1000;
const SITE_VERSION_KEY = 'x_version';

export const useOnlinePing = (isAuthenticated: boolean) => {
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const pingCount = useRef<number>(0);

  const pingOnline = useCallback(async () => {
    if (!isAuthenticated) return null;

    const siteVersion = await AuthApi.getSiteVersion();
    const siteVersionFromStorage = getSessionStorageItem(SITE_VERSION_KEY);

    if (
      siteVersion &&
      siteVersionFromStorage &&
      siteVersion !== siteVersionFromStorage
    ) {
      setSessionStorageItem(SITE_VERSION_KEY, siteVersion);

      if (pingCount.current > 0) {
        window.location?.reload();
      }
    }

    if (siteVersion && !siteVersionFromStorage) {
      setSessionStorageItem(SITE_VERSION_KEY, siteVersion);
    }

    pingCount.current += 1;
    AuthApi.pingOnline(siteVersion);

    timeoutId.current = setTimeout(pingOnline, PING_TIMEOUT);

    return null;
  }, [isAuthenticated]);

  useEffect(() => {
    pingOnline();

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [pingOnline]);
};
