import React, { memo, useCallback, useEffect } from 'react';

import { PWA_POPUPS } from 'helpers/pwa/constants';
import { usePWAAnalytics } from 'hooks/analytics/usePWAPopupAnalytics';
import { usePromotionPWA } from 'hooks/usePromotionPWA';
import { PWAPopupParams } from 'store/systemPopup/systemPopupSlice';

import { Default, Mobile } from 'components/shared/MediaComponents';
import { Popup } from 'components/shared/Popup';
import { PopupMobile } from 'components/shared/PopupMobile';

import { PromotePopupContent } from './PromotePopupContent';
import { WizardPopupContent } from './WizardPopupContent';

interface Props {
  params: PWAPopupParams;
  onClose: () => void;
}

export const PromotePWAPopup: React.FC<Props> = memo(({ params, onClose }) => {
  const { handlePWAPromotionButtonSubmit } = usePromotionPWA();
  const { trackPWAShow, trackPWAClose, trackPWAClick } = usePWAAnalytics();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    trackPWAClick(params.name);

    handlePWAPromotionButtonSubmit(PWA_POPUPS.SystemPWA);
    handleClose();
  }, [handleClose, handlePWAPromotionButtonSubmit, params.name, trackPWAClick]);

  useEffect(() => {
    trackPWAShow(params.name);

    return () => {
      trackPWAClose(params.name);
    };
  }, [params.name, trackPWAClose, trackPWAShow]);

  if (params.isWizard) {
    return <WizardPopupContent onSubmit={handleSubmit} onClose={handleClose} />;
  }

  return (
    <>
      <Mobile>
        <PopupMobile isOpen onClose={handleClose}>
          <PromotePopupContent onSubmit={handleSubmit} onClose={handleClose} />
        </PopupMobile>
      </Mobile>

      <Default>
        <Popup isIndented={false} onClose={handleClose}>
          <PromotePopupContent onSubmit={handleSubmit} onClose={handleClose} />
        </Popup>
      </Default>
    </>
  );
});
