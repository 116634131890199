export const getNetworkParams = () => {
  return {
    // @ts-ignore
    ...(navigator?.connection && {
      // @ts-ignore
      rtt: navigator.connection?.rtt,
      // @ts-ignore
      downlink: navigator.connection?.downlink,
      // @ts-ignore
      effective_type: navigator.connection?.effectiveType,
    }),
  };
};
