import { useEffect } from 'react';

import { TrackingApi } from 'api/TrackingApi';
import { getScreenOrientation, getScreenSizes } from 'helpers/device';

export const useDeviceRotation = () => {
  useEffect(() => {
    const handleDeviceRotation = () => {
      TrackingApi.trackScreenOrientation({
        screen: {
          orientation:
            getScreenOrientation() === 'portrait' ? 'landscape' : 'portrait',
          ...getScreenSizes(),
        },
      });
    };

    window.addEventListener('orientationchange', handleDeviceRotation);

    return () =>
      window.removeEventListener('orientationchange', handleDeviceRotation);
  }, []);
};
