import React, { memo, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Zendesk, { ZendeskAPI } from 'react-zendesk';

import { useSystemSearchQueries } from 'hooks/useSystemSearchQueries';

const ZENDESK_KEY = 'fbbeb19f-6d5a-41df-aca7-dbffef124e84';

const ZENDESK_ENABLED_PATHNAME = [
  '/my-profile',
  '/my-profile/edit',
  '/my-profile/settings',
  '/my-profile/settings/account',
  '/my-profile/settings/notifications',
  '/my-profile/help-center',
  '/my-profile/contact-us',
];

const ZENDESK_PAYMENT_PATHNAME = ['/payment/one-click', '/payment/card'];

export const ZendeskWidget = memo(() => {
  const { pathname } = useLocation();

  const { supportWidgetQuery } = useSystemSearchQueries();

  const [isInitialized, setIsInitialized] = useState(false);

  const handleZendeskVisible = useCallback(() => {
    const isZendeskWidgetShow = ZENDESK_ENABLED_PATHNAME.find(
      (enabledPathname) => enabledPathname === pathname
    );
    const isZendeskPaymentWidgetShow =
      ZENDESK_PAYMENT_PATHNAME.find((enabledPathname) =>
        pathname.includes(enabledPathname)
      ) && supportWidgetQuery.value;

    ZendeskAPI(
      'messenger',
      isZendeskWidgetShow || isZendeskPaymentWidgetShow ? 'show' : 'hide'
    );

    if (isZendeskPaymentWidgetShow) {
      ZendeskAPI('messenger', 'open');
    }
  }, [pathname, supportWidgetQuery.value]);

  const handleZendeskLoad = useCallback(() => {
    setIsInitialized(true);

    handleZendeskVisible();
  }, [handleZendeskVisible]);

  useEffect(() => {
    if (isInitialized) {
      handleZendeskVisible();
    }
  }, [handleZendeskVisible, isInitialized]);

  return (
    <Zendesk defer onLoaded={handleZendeskLoad} zendeskKey={ZENDESK_KEY} />
  );
});
