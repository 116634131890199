import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { MirrorService } from 'services/MirrorService';
import { EmailSubscriptionStatus } from 'types/enums/EmailSubscriptionStatus';

import {
  getIsConfirmationResent,
  setIsConfirmationResent,
} from 'helpers/emailConfirmation';
import { getErrorMessageWithDefault } from 'helpers/errors';
import {
  IS_PWA_ON_MAIL_CONFIRM_SHOWN,
  PWA_POPUPS,
} from 'helpers/pwa/constants';
import { getSessionStorageItem } from 'helpers/sessionStorage';
import {
  ConfirmationPopupName,
  ConfirmationPopupOperation,
  useEmailConfirmationPopupAnalytics,
} from 'hooks/analytics/useEmailConfirmationPopupAnalytics';
import { useEmailConfirmationMutation } from 'hooks/mutations/useEmailConfirmationMutation';
import { usePromotionPWA } from 'hooks/usePromotionPWA';
import { useToast } from 'hooks/useToast';
import {
  getUser,
  getUserEmail,
  getUserEmailSubscriptionStatus,
} from 'store/auth/selectors';
import {
  updateConfirmMailFromNotificationPopup,
  updateConfirmMailFromProfilePopup,
} from 'store/common/commonSlice';
import {
  getIsConfirmationProfilePopupShownSelector,
  getIsEnabledPWASystemPopupSelector,
  getPrevPagePathname,
} from 'store/common/selectors';
import { getLastActiveUserProfileInfo } from 'store/profile/selectors';

import { ReactComponent as ConfirmationSendIcon } from 'assets/icons/confirmation-send.svg';
import { ReactComponent as LeftLinesIcon } from 'assets/icons/popups/lines-left.svg';
import { ReactComponent as RightLinesIcon } from 'assets/icons/popups/lines-right.svg';

import { BaseButton, ButtonTypes } from 'components/base/BaseButton';
import { Popup } from 'components/shared/Popup';
import { UserAvatar } from 'components/shared/UserAvatar';

import css from './mailConfirmationPopup.module.sass';

const PAGES_TO_IGNORE_CONFIRMATION = [
  '/dialogs',
  '/mails',
  '/upgrade',
  '/payment ',
  '/wizard',
];

const PAGES_TO_TRIGGER_CONFIRMATION = ['/user', '/dialogs', '/mails'];

export const MailConfirmationPopup: React.FC = memo(() => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { showErrorToast, showSuccessToast } = useToast();

  const { trackEmailConfirmationPopup } = useEmailConfirmationPopupAnalytics();
  const { isCanPromotePWA, handlePWAPromotionButtonSubmit } = usePromotionPWA();

  const isConfirmationProfilePopupShown = useSelector(
    getIsConfirmationProfilePopupShownSelector
  );
  const isEnabledPWASystemPopup = useSelector(
    getIsEnabledPWASystemPopupSelector
  );

  const prevPagePathname = useSelector(getPrevPagePathname);
  const userEmail = useSelector(getUserEmail);
  const userEmailSubscriptionStatus = useSelector(
    getUserEmailSubscriptionStatus
  );

  const lastActiveUserProfile = useSelector(getLastActiveUserProfileInfo);
  const currentUser = useSelector(getUser);

  const handleResentSuccess = useCallback(() => {
    dispatch(updateConfirmMailFromProfilePopup());
    dispatch(updateConfirmMailFromNotificationPopup());

    setIsConfirmationResent();
    showSuccessToast({ title: 'The mail has been resent!' });
  }, [dispatch, showSuccessToast]);

  const handleResentError = useCallback(
    (err: any) => {
      showErrorToast({
        title: getErrorMessageWithDefault(err),
      });
    },
    [showErrorToast]
  );

  const { mutate: sendConfirmation, isLoading } = useEmailConfirmationMutation({
    onError: handleResentError,
    onSuccess: handleResentSuccess,
  });

  const handleSubmit = useCallback(() => {
    sendConfirmation();

    trackEmailConfirmationPopup({
      popupName: ConfirmationPopupName.ConfirmationProfilePopup,
      operation: ConfirmationPopupOperation.ClickAccept,
    });
  }, [sendConfirmation, trackEmailConfirmationPopup]);

  const handleChangeMail = useCallback(() => {
    dispatch(updateConfirmMailFromProfilePopup());
    dispatch(updateConfirmMailFromNotificationPopup());

    trackEmailConfirmationPopup({
      popupName: ConfirmationPopupName.ConfirmationProfilePopup,
      operation: ConfirmationPopupOperation.ClickChange,
    });

    history.push('/my-profile/settings/account/change-email');
  }, [dispatch, history, trackEmailConfirmationPopup]);

  const handleClose = useCallback(() => {
    const isPWAOnMailConfirmShown = getSessionStorageItem(
      IS_PWA_ON_MAIL_CONFIRM_SHOWN
    );

    dispatch(updateConfirmMailFromProfilePopup());

    trackEmailConfirmationPopup({
      popupName: ConfirmationPopupName.ConfirmationProfilePopup,
      operation: ConfirmationPopupOperation.Close,
    });

    if (
      !isPWAOnMailConfirmShown &&
      isEnabledPWASystemPopup &&
      isCanPromotePWA
    ) {
      handlePWAPromotionButtonSubmit(PWA_POPUPS.AfterProfile);
    }
  }, [
    dispatch,
    handlePWAPromotionButtonSubmit,
    isCanPromotePWA,
    isEnabledPWASystemPopup,
    trackEmailConfirmationPopup,
  ]);

  const isNotificationDisabledByPage = useMemo(
    () =>
      PAGES_TO_IGNORE_CONFIRMATION.some((pageItem) =>
        pathname.includes(pageItem)
      ),
    [pathname]
  );

  const isPrevPageUserPage = useMemo(
    () =>
      PAGES_TO_TRIGGER_CONFIRMATION.some((pageItem) =>
        prevPagePathname.includes(pageItem)
      ),
    [prevPagePathname]
  );

  const shouldHideConfirmationPopup = useMemo(
    () =>
      !!getIsConfirmationResent() ||
      !lastActiveUserProfile ||
      userEmailSubscriptionStatus !==
        EmailSubscriptionStatus.ConfirmationSend ||
      isConfirmationProfilePopupShown ||
      isNotificationDisabledByPage ||
      !isPrevPageUserPage ||
      !userEmail,
    [
      lastActiveUserProfile,
      userEmailSubscriptionStatus,
      isConfirmationProfilePopupShown,
      isNotificationDisabledByPage,
      isPrevPageUserPage,
      userEmail,
    ]
  );

  useEffect(() => {
    if (!shouldHideConfirmationPopup)
      trackEmailConfirmationPopup({
        popupName: ConfirmationPopupName.ConfirmationProfilePopup,
        operation: ConfirmationPopupOperation.Show,
      });
  }, [shouldHideConfirmationPopup, trackEmailConfirmationPopup]);

  if (shouldHideConfirmationPopup) return null;

  return (
    <Popup onClose={handleClose} bodyClassName={css.root}>
      <div className={css.header}>
        <LeftLinesIcon className={css.linesIcon} />
        <UserAvatar
          size="66px"
          className={css.avatar}
          url={MirrorService.resolveImagePath(lastActiveUserProfile?.photoUrl)}
        />
        <div className={css.sendIconWrapper}>
          <ConfirmationSendIcon className={css.sendIcon} />
        </div>
        <UserAvatar
          size="66px"
          className={css.avatar}
          url={MirrorService.resolveImagePath(
            currentUser?.photo_url || currentUser?.photos?.[0]?.big_url
          )}
        />
        <RightLinesIcon className={css.linesIcon} />
      </div>

      <p className={css.title}>Don’t miss anything</p>
      <p className={css.subtitle}>Please confirm your email address</p>
      <p className={css.email}>{userEmail || '---'}</p>

      <BaseButton
        className={css.changeBtn}
        type={ButtonTypes.Transparent}
        onClick={handleChangeMail}
        disabled={isLoading}
      >
        Change Email
      </BaseButton>

      <BaseButton
        className={css.submitBtn}
        type={ButtonTypes.Accent}
        onClick={handleSubmit}
        loading={isLoading}
      >
        Resend Email
      </BaseButton>
    </Popup>
  );
});
